import { createIcon } from "../create-icon"

export const SorterDefaultIcon = createIcon({
  title: "SorterDefaultIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M6.216 9.324A.208.208 0 0 1 6.389 9h3.222c.166 0 .265.185.173.324l-1.368 2.052a.5.5 0 0 1-.832 0L6.216 9.324zM6.216 6.676A.208.208 0 0 0 6.389 7h3.222a.208.208 0 0 0 .173-.324L8.416 4.624a.5.5 0 0 0-.832 0L6.216 6.676z"
      fill="#C2C2C2"
    />
  ),
})

SorterDefaultIcon.displayName = "SorterDefaultIcon"

import { createIcon } from "@illa-design/icon"

export const RawTip = createIcon({
  title: "Raw",
  viewBox: "0 0 23 12",
  path: (
    <>
      <rect x="0.5" y="0.5" width="22" height="11" rx="2.5" fill="#F2F3F5" />
      <path
        d="M3.10014 9V3.18182H5.17401C5.62476 3.18182 5.99882 3.25947 6.29616 3.41477C6.59541 3.57008 6.81889 3.78504 6.96662 4.05966C7.11435 4.33239 7.18821 4.64773 7.18821 5.00568C7.18821 5.36174 7.1134 5.67519 6.96378 5.94602C6.81605 6.21496 6.59257 6.42424 6.29332 6.57386C5.99598 6.72348 5.62192 6.7983 5.17116 6.7983H3.60014V6.04261H5.09162C5.37571 6.04261 5.60677 6.00189 5.7848 5.92045C5.96473 5.83902 6.09635 5.72064 6.17969 5.56534C6.26302 5.41004 6.30469 5.22348 6.30469 5.00568C6.30469 4.78598 6.26207 4.59564 6.17685 4.43466C6.09351 4.27367 5.96188 4.15057 5.78196 4.06534C5.60393 3.97822 5.37003 3.93466 5.08026 3.93466H3.97798V9H3.10014ZM5.9723 6.375L7.4098 9H6.4098L5.00071 6.375H5.9723ZM8.74787 9H7.81605L9.9098 3.18182H10.924L13.0178 9H12.0859L10.4411 4.23864H10.3956L8.74787 9ZM8.90412 6.72159H11.9268V7.46023H8.90412V6.72159ZM14.4972 9L12.8835 3.18182H13.8068L14.9403 7.6875H14.9943L16.1733 3.18182H17.0881L18.267 7.69034H18.321L19.4517 3.18182H20.3778L18.7614 9H17.8778L16.6534 4.64205H16.608L15.3835 9H14.4972Z"
        fill="#1D2129"
      />
      <rect x="0.5" y="0.5" width="22" height="11" rx="2.5" stroke="white" />
    </>
  ),
})

RawTip.displayName = "RawTip"

import { createIcon } from "../create-icon"

export const DownloadIcon = createIcon({
  title: "DownloadIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5496 10.7603C8.40769 10.9132 8.20856 11 8 11C7.79145 11 7.59232 10.9132 7.45041 10.7603L4.20041 7.26034C3.91856 6.95681 3.93613 6.48226 4.23966 6.2004C4.5432 5.91855 5.01775 5.93613 5.2996 6.23966L7.25 8.3401V2.75C7.25 2.33579 7.58579 2 8 2C8.41422 2 8.75 2.33579 8.75 2.75V8.3401L10.7004 6.23966C10.9823 5.93613 11.4568 5.91855 11.7603 6.2004C12.0639 6.48226 12.0815 6.95681 11.7996 7.26034L8.5496 10.7603ZM3.5 10.75C3.5 10.3358 3.16421 10 2.75 10C2.33579 10 2 10.3358 2 10.75V13.25C2 13.6642 2.33579 14 2.75 14H13.2491C13.6633 14 13.9991 13.6642 13.9991 13.25V10.75C13.9991 10.3358 13.6633 10 13.2491 10C12.8349 10 12.4991 10.3358 12.4991 10.75V12.5H3.5V10.75Z"
      fill="currentColor"
    />
  ),
})

DownloadIcon.displayName = "DownloadIcon"

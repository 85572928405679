import { createIcon } from "../create-icon"

export const RefreshIcon = createIcon({
  title: "RefreshIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38889 6.88889C9.31522 6.88889 9.24456 6.85962 9.19247 6.80753C9.14038 6.75544 9.11111 6.68478 9.11111 6.61111V6.05556C9.11111 5.98188 9.14038 5.91123 9.19247 5.85914C9.24456 5.80704 9.31522 5.77778 9.38889 5.77778H11.1917C10.4889 4.77028 9.32167 4.11111 8 4.11111C5.85222 4.11111 4.11111 5.85222 4.11111 8C4.11111 10.1478 5.85222 11.8889 8 11.8889C9.76167 11.8889 11.2497 10.7175 11.7278 9.11111H12.8761C12.3706 11.3381 10.3794 13 8 13C5.23861 13 3 10.7614 3 8C3 5.23861 5.23861 3 8 3C9.57083 3 10.9725 3.72444 11.8892 4.8575L11.8889 3.27778C11.8889 3.20411 11.9182 3.13345 11.9702 3.08136C12.0223 3.02927 12.093 3 12.1667 3H12.7222C12.7959 3 12.8665 3.02927 12.9186 3.08136C12.9707 3.13345 13 3.20411 13 3.27778V6.33333C13 6.47345 12.947 6.60837 12.8517 6.71109C12.7564 6.8138 12.6258 6.87671 12.4861 6.88722L12.4444 6.88889H9.38889Z"
      fill="currentColor"
    />
  ),
})

RefreshIcon.displayName = "RefreshIcon"
